// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._95kmECwY{background:#549445;border:1px solid #fff;border-radius:.5rem;min-width:0;min-width:auto;overflow:hidden;padding:0;width:2rem}._9oGjuUrU{color:#fff;padding:.25rem}._9oGjuUrU:hover{background:#5ea54d;color:#fff}._9oGjuUrU:active{background:#4a833d}._9oGjuUrU svg>*{fill:currentColor}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"links": "_95kmECwY",
	"link": "_9oGjuUrU"
};
module.exports = ___CSS_LOADER_EXPORT___;
