// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sdQuntJK{gap:13rem;margin-bottom:2.5rem}@media(max-width:1365.98px){.sdQuntJK{gap:2rem}}@media(max-width:991.98px){.sdQuntJK{flex-direction:column;margin-bottom:2rem}}@media(max-width:575.98px){.sdQuntJK{gap:1rem;margin-bottom:1.5rem}}.uvNULHqi{font-size:1.88rem;letter-spacing:.02em;line-height:1.4;margin-bottom:.5em}@media(max-width:991.98px){.uvNULHqi{font-size:1.5rem}}@media(max-width:575.98px){.uvNULHqi{font-size:1.13rem}}.s81HOWee{display:flex;flex-direction:column;gap:max(.28rem,min(.42vw,.5rem))}@media(max-width:991.98px){.s81HOWee{flex-direction:row;flex-wrap:wrap}}.hxqUh-jO{font-size:max(.75rem,min(.73vw,.88rem));padding:.6em .8em}.G8scBZ\\+Q{align-items:center;font-size:1rem;gap:1.25em}@media(max-width:991.98px){.G8scBZ\\+Q{font-size:.88rem}}@media(max-width:575.98px){.G8scBZ\\+Q{font-size:.75rem}}.T4SzmNTG{border-radius:.3em;font-family:Sofia_Sans_Extra_Condensed,sans-serif;font-size:max(1rem,min(1.15vw,1.38rem));font-weight:700;letter-spacing:.02em;line-height:1.44;text-transform:uppercase;white-space:nowrap}._3kCAnjPR{background:#92f22b;padding:.38em .5em .19em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "sdQuntJK",
	"title": "uvNULHqi",
	"btns": "s81HOWee",
	"programBtn": "hxqUh-jO",
	"labels": "G8scBZ+Q",
	"label": "T4SzmNTG",
	"labelPrimary": "_3kCAnjPR"
};
module.exports = ___CSS_LOADER_EXPORT___;
