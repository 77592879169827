
import { isSessionLive } from "@/utils/_"

export default {
  props: {
    session: { type: Object, required: true },
    isBreak: { type: Boolean, default: false },
    withFullDate: { type: Boolean, default: false },
  },

  data: () => ({
    timer: "",
    timerIntervalId: null,
  }),

  computed: {
    isLive () {
      return isSessionLive(this.session)
    },

    date () {
      const date = this.session.start
      const format = this.withFullDate ? "DD.MM.YYYY HH:mm" : "D MMM"
      return this.$fmt.date(date, format)
    },
  },

  mounted () { this.startTimer() },
  beforeDestroy () { this.stopTimer() },

  methods: {
    setTimerValue () {
      if (!this.isLive || this.isBreak) this.timer = ""
      else {
        const { $moment: m } = this
        const start = m(this.session.start)
        const diff = m().diff(start)
        this.timer = m.utc(diff).format("HH:mm:ss")
      }
    },
    startTimer () {
      const { setTimerValue } = this
      setTimerValue()
      this.timerIntervalId = setInterval(setTimerValue, 1000)
    },
    stopTimer () {
      clearInterval(this.timerIntervalId)
    },
  },
}
