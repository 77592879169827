// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fE50yDS9{margin-bottom:8.75rem}@media(max-width:1365.98px){.fE50yDS9{margin-bottom:6.25rem}}@media(max-width:991.98px){.fE50yDS9{margin-bottom:5rem}}@media(max-width:575.98px){.fE50yDS9{margin-bottom:3.13rem}}.HT8s9-sd{margin:0 auto;max-width:100%;width:62.5rem}.PxH4Uwa3{aspect-ratio:16/9;border-radius:1.5rem;margin-bottom:1.88rem}@media(max-width:991.98px){.PxH4Uwa3{border-radius:1rem;margin-bottom:1.5rem}}@media(max-width:575.98px){.PxH4Uwa3{border-radius:.5rem;margin-bottom:1rem}}.hXg963wM{border-radius:inherit;height:100%;width:100%}.hXg963wM>*{border-radius:inherit;height:100%!important;width:100%!important}.fRe4NMnl,.hXg963wM>*{background:rgba(0,0,0,.3)}@supports not (aspect-ratio:16/9){.PxH4Uwa3{padding-top:56.25%;position:relative}.hXg963wM{left:0;position:absolute;top:0}}.PmN048G\\+{font-size:1.13rem;line-height:1.39;margin-top:3em}@media(max-width:991.98px){.PmN048G\\+{font-size:1rem}}@media(max-width:767.98px){.PmN048G\\+{font-size:.88rem;margin-top:2em}}.Rvbz4aPo{padding-top:max(2.25rem,min(3.33vw,4rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "fE50yDS9",
	"inner": "HT8s9-sd",
	"playerContainer": "PxH4Uwa3",
	"player": "hXg963wM",
	"playerPlaceholder": "fRe4NMnl",
	"description": "PmN048G+",
	"participants": "Rvbz4aPo"
};
module.exports = ___CSS_LOADER_EXPORT___;
